import revive_payload_client_6LyVd6ak1X from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.1_ioredis@5.4.1_magicast@0.3.5_rollup@4._mtsfu4mlb56qx5pr5hdewij54e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_O0khQraWve from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.1_ioredis@5.4.1_magicast@0.3.5_rollup@4._mtsfu4mlb56qx5pr5hdewij54e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_fkA7xPPzmx from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.1_ioredis@5.4.1_magicast@0.3.5_rollup@4._mtsfu4mlb56qx5pr5hdewij54e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_OT6lkQ0csy from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.1_ioredis@5.4.1_magicast@0.3.5_rollup@4._mtsfu4mlb56qx5pr5hdewij54e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_xcd8cJP65y from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.1_ioredis@5.4.1_magicast@0.3.5_rollup@4._mtsfu4mlb56qx5pr5hdewij54e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_beHXBs8wvc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.1_ioredis@5.4.1_magicast@0.3.5_rollup@4._mtsfu4mlb56qx5pr5hdewij54e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GJH10m7dUE from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.1_ioredis@5.4.1_magicast@0.3.5_rollup@4._mtsfu4mlb56qx5pr5hdewij54e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_wGkOF4mzBM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.1_ioredis@5.4.1_magicast@0.3.5_rollup@4._mtsfu4mlb56qx5pr5hdewij54e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_6LyVd6ak1X,
  unhead_O0khQraWve,
  router_fkA7xPPzmx,
  payload_client_OT6lkQ0csy,
  navigation_repaint_client_xcd8cJP65y,
  check_outdated_build_client_beHXBs8wvc,
  chunk_reload_client_GJH10m7dUE,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wGkOF4mzBM
]